/**
 * @name: 商品管理-商品单位管理接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 商品管理-商品单位管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
/**
 *分页查询
 */
export const productUnitQueryApi = () => get("/admin/productUnit/query/list")

/**
 * 修改状态
 */
export const productUnitSetStatusApi = (id:string) => get("/admin/productUnit/setStatus/"+id)

/**
 *创建
 */
export const productUnitCreateApi = (data:any) => postJ("/admin/productUnit/create", data)
