
/**
 * @name: 商品管理-商品单位管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 商品管理-商品单位管理
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IPage} from "@/apis/page";
import {IProductSortCreat} from "@/apis/product/sort/types";
import {productUnitCreateApi, productUnitQueryApi, productUnitSetStatusApi} from "@/apis/product/unit";

@Component({})
export default class productUnit extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: [] = []
  // 表格总数
  tableTotal: number = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10,
  }
  // 表单参数
  modelForm: Partial<IProductSortCreat> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    addTitle: '新增单位',
    menu: false,
    dialogWidth: '400px',
    column: [
      {
        label: "单位",
        prop: "unitName",
        align: "center",
        span: 24,
        maxlength: 2,
        rules: [
          {required: true, message: "请输入单位", trigger: "blur"},
          {
            trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
              if (!/^[\da-z\u2E80-\u9FFF]{1,2}$/i.test(value)) {
                callback(new Error('数据类型数字、字母、汉字，最大长度2'));
              } else {
                callback();
              }
            }
          }
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        value: 2,
        type: "switch",//状态:1=启用 2=禁用
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        addHide: true
      },
    ]
  }

  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    productUnitCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改状态提交
   * @param row
   */
  handleStatus(row: any) {
    //@ts-ignore
    productUnitSetStatusApi(row.id).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  /**
   * 获取列表数据
   */
  getList() {
    productUnitQueryApi().then(e => {
      //@ts-ignore
      this.tableData = e;
    })
  }

  created() {
    this.getList()
  }
}
